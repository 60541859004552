import { ApolloClient } from "@apollo/client";
import { datadogRum } from "@datadog/browser-rum";
import {
  AmplifyConfig,
  createApolloClientWithAuth,
  GraphqlClientOptions,
  HomeboundUser,
} from "@homebound/auth-components";
import md5 from "md5";
import ReactGA from "react-ga";
import { possibleTypes } from "src/generated/graphql-types";
import { enumTypePolicies } from "./generated/graphql-types";
import { assetTypePolicy } from "./resolvers/Asset";

type Stage = "local" | "dev" | "qa" | "prod";

export function getStage(windowLocation = window.location): Stage {
  // Look for an ?stage=... override
  const maybeQueryStringStage = new URLSearchParams(window.location.search).get("stage") as Stage | undefined;
  if (maybeQueryStringStage) {
    return maybeQueryStringStage;
  }

  // Guess stage based on the current window.location.hostname
  const hostname = windowLocation.hostname;
  if (hostname.includes("dev-homebound")) {
    return "dev";
  } else if (hostname.includes("qa-homebound")) {
    return "qa";
  } else if (hostname.includes("homebound")) {
    return "prod";
  } else {
    return "local";
  }
}

export function getExploreUrl(checkoutId?: string | null, stage: Stage = getStage()): string {
  const stageToExploreUrl: Record<Stage, string> = {
    local:
      "VITE_GRAPHQL_SERVICE" in global && VITE_GRAPHQL_SERVICE
        ? "https://explore.dev-homebound.com"
        : "http://localhost:1337",
    dev: "https://explore.dev-homebound.com",
    qa: "https://explore.qa-homebound.com",
    prod: "https://explore.homebound.com",
  };

  return `${stageToExploreUrl[stage]}/hop-selections/${checkoutId}`;
}

function getGraphQLBaseUrl(stage: Stage = getStage()): string {
  const stageToGraphQL: Record<Stage, string> = {
    local: "VITE_GRAPHQL_SERVICE" in global && VITE_GRAPHQL_SERVICE ? VITE_GRAPHQL_SERVICE : "http://localhost:4000",
    dev: "https://graphql.dev-homebound.com",
    qa: "https://graphql.qa-homebound.com",
    prod: "https://graphql.homebound.com",
  };
  return stageToGraphQL[stage];
}

interface CreateApolloClientOpts {}

export async function createApolloClient(opts: CreateApolloClientOpts = {}): Promise<ApolloClient<unknown>> {
  const stage: Stage = getStage();
  const options: GraphqlClientOptions = {
    serviceName: "homeowner-frontend",
    serviceVersion: VITE_GIT_COMMIT ?? "local",
    inMemoryCacheOptions: {
      // `as any` because our possibleTypes is generated with `as const` which makes readonly arrays,
      // but Apollo accepts mutable arrays.
      possibleTypes: possibleTypes as any,
      typePolicies: {
        ...assetTypePolicy,
        ...enumTypePolicies,
        HomeownerInbox: { merge: true },
      },
    },
    apolloClientOptions: {
      connectToDevTools: stage !== "prod",
      assumeImmutableResults: true,
      defaultOptions: {
        watchQuery: {
          // Use the cache first, but also make the network request in case something has changed
          // This is optimal for keeping the app cache in sync with the server while still providing
          // a very responsive UI
          fetchPolicy: "cache-and-network",
        },
      },
    },
  };

  return createApolloClientWithAuth(`${getGraphQLBaseUrl(stage)}/graphql`, options);
}

export function amplifyConfig(stage: Stage = getStage()): AmplifyConfig {
  function getStageConfig() {
    if (stage === "prod") {
      return {
        userPoolId: "us-west-2_EwgDlC8SO",
        userPoolWebClientId: "2jm02vg56djti5s38h35cvuukh",
        oauthDomain: "homeowner-auth.homebound.com",
      };
    }

    return {
      userPoolId: "us-west-2_02OX5J146",
      userPoolWebClientId: "467351l744rcg2nheppufd05fm",
      oauthDomain: "homeowner-auth.dev-homebound.com",
      cookieStorage: {
        // Use .<stage>-homebound.com so that our cookies get sent to graphql.<stage>-homebound.com, which
        // knows to look for the cookie as well as the usual Authentication header, so that the
        // GraphQL playground gets auth "for free".
        //
        // This domain pattern is too broad for production, i.e. it would leak our auth cookies onto
        // domains like `somechatvendor.homebound.com`, but it's fine for dev creds.
        //
        // We should also not conflict/overlap with other apps like `app.<stage>-homebound.com` because
        // Cognito puts the application-specific user pool id into the cookie names.
        domain: stage === "local" ? "localhost" : `.${stage}-homebound.com`,
        // Don't use secure so that the cookie works on the http://localhost:4000/graphql GraphQL playground.
        secure: false,
      },
    };
  }

  const { userPoolId, userPoolWebClientId, oauthDomain, cookieStorage } = getStageConfig();
  const { origin } = window.location;

  return {
    Auth: {
      region: "us-west-2",
      userPoolId,
      userPoolWebClientId,
      authenticationFlowType: "CUSTOM_AUTH",
      oauth: {
        domain: oauthDomain,
        scope: ["profile", "email", "openid", "aws.cognito.signin.user.admin"],
        redirectSignIn: origin + "/auth/gcallback",
        redirectSignOut: origin + "/",
        responseType: "code",
      },
      cookieStorage,
    },
  };
}

export function configureDatadog(stage = getStage()) {
  if (stage === "local") {
    return;
  }

  // https://docs.datadoghq.com/real_user_monitoring/browser/#configuration
  datadogRum.init({
    applicationId: "963442ac-414a-4309-a5f2-44bc674e9c60",
    clientToken: "pub0cc73d5f48ad9d483be5ea01c6d16081",
    site: "datadoghq.com",
    service: "homeowner-frontend",
    env: stage,
    version: VITE_GIT_COMMIT ?? "local",
    sampleRate: 100,
    replaySampleRate: 100,
    trackInteractions: true,
    allowedTracingOrigins: [getGraphQLBaseUrl(stage)],
  });
  datadogRum.startSessionReplayRecording();
}

function md5UserId(user: HomeboundUser | undefined) {
  if (user) {
    return md5(user.email);
  }
}

interface Heap {
  identify: (email?: string) => void;
}

export type WindowWithHeap = Window & typeof globalThis & { heap: Heap };

export function identifyHeap(user: HomeboundUser | undefined) {
  if (user) {
    (window as WindowWithHeap).heap?.identify(md5UserId(user));
  }
}

export function initializeGoogleAnalytics() {
  ReactGA.initialize(getGATrackingId());
  ReactGA.pageview(window.location.pathname + window.location.search);
}

export function addGATracking(user: HomeboundUser | undefined, history: any) {
  // Set user id
  ReactGA.set({ userId: md5UserId(user) });

  // Set page tracking
  history.listen((location: Location) => {
    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname + location.search);
  });
}

/**
 *
 * @param {string} category - A top level category for these events. E.g. 'User', 'Navigation', 'App Editing', etc.
 * @param {string} action - A description of the behaviour. E.g. 'Clicked Delete', 'Added a component', 'Deleted account', etc.
 */
export function addGAEvent(category: string, action: string) {
  ReactGA.event({ category, action });
}

function getGATrackingId(stage: Stage = getStage()) {
  if (stage === "prod") {
    return "UA-173657460-1";
  } else {
    return "UA-173657460-2";
  }
}
